<template>
  <div class="map-block" v-if="showMap">
    <l-map
        ref="map"
        v-on="$listeners"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        @ready="_onMapReady"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
    >
      <l-tile-layer
          :url="url"
          :subdomains="subdomains"
          :attribution="attribution"
          :options="{ maxNativeZoom: 18, maxZoom: 19 }"
      ></l-tile-layer>

      <l-control position="topright" v-if="showCenterControl && selectedItem && selectedItem.position">

        <div class="mb-1">
          <v-btn icon  v-if="!isSelectItemVisible || currentZoom !== 18" @click="flyToItem(selectedItem.position)">
            <v-icon>mdi-map-search-outline</v-icon>
          </v-btn>
          <v-btn v-else icon @click="fitBounds">
            <v-icon>mdi-map-outline</v-icon>
          </v-btn>
        </div>

      </l-control>

      <l-control position="bottomright" v-if="showCenterControl && selectedItem && selectedItem.position">

        <div>
          <v-btn icon @click="centerMap">
            <v-icon>mdi-image-filter-center-focus</v-icon>
          </v-btn>
        </div>
      </l-control>

      <l-marker
          v-if="selectedItem && selectedItem.position"
          :icon="selectedIcon"
          :lat-lng="[selectedItem.position.latitude, selectedItem.position.longitude]"
          @click="openChargingPoint(selectedItem)"
          :z-index-offset="9000"
      />


      <template v-for="(item, i) in items">
        <l-circle-marker
            v-if="item.position && !hideCanvaMarker"
            :key="'m-' + i"
            :weight=".7"
            :lat-lng="[item.position.latitude, item.position.longitude]"
            :radius="6"
            :fill-opacity="opacityForStation(item)"
            color="white"
            :fill-color="colorForStation(item)"
            @click="openChargingPoint(item)"

        />
      </template>

      <slot></slot>
    </l-map>
  </div>
</template>


<script>
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';

import {LMap, LTileLayer, LControl, LCircleMarker} from "vue2-leaflet";
import {LMarker} from "vue2-leaflet";
import {Icon, LatLng} from "leaflet";
import icon from "@images/marker.svg";
import iconShadow from "@images/marker-shadow.png";
import iconSelected from "@images/marker-selected.svg";


export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    LControl,
    LMarker,
  },
  data() {


    return {
      showMap: false,
      // url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      // attribution: 'Tiles © Esri — Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

      // OSM
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      subdomains: null,
      maxZoom: 21,

      hideCanvaMarker: false,

      // GoogleMap
      // url: 'http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}',
      // subdomains:['mt0','mt1','mt2','mt3'],
      // maxZoom: 20,

      // url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
      attribution: '',
      // currentZoom: 11.5,
      // currentCenter: latLng(47.41322, 3.332236),
      mapOptions: {
        preferCanvas: true,
        zoomSnap: 0.5,
      },
      currentZoom: null,
      currentCenter: null,
      isSelectItemVisible: false,

      clusterOptions: {
        showCoverageOnHover: false,
        // disableClusteringAtZoom: 10,
        // spiderfyOnMaxZoom: true,
      },

      defaultIcon: new Icon({
        iconUrl: icon,
        iconSize: [50, 72],
        iconAnchor: [25, 72],
        shadowUrl: iconShadow,
        shadowSize: [41, 41],
        shadowAnchor: [13, 41]
      }),


      selectedIcon: new Icon({
        iconUrl: iconSelected,
        iconSize: [50, 72],
        iconAnchor: [25, 72],
        shadowUrl: iconShadow,
        shadowSize: [41, 41],
        shadowAnchor: [13, 41]
      }),
    }
  },

  props: {
    zoom: {type: Number, default: 5},
    center: {type: Array, default: () => [47.41322, 1.619482]},
    showCenterControl: {type: Boolean, default: true},
    items: {type: Array, default: () => ([])},
    selectedItem: Object,
    onMapReady: {type: Function, default: () => {}},
    isLocation: {type: Boolean, default: false},
  },



  created() {
    this.map = null
  },

  mounted() {
    this.showMap = true
  },

  watch: {
    items(items) {
      if(items.length > 0) {
        this.$nextTick(() => {
          this.map.invalidateSize()
          this.fitBounds()
        })
      }
    },

    selectedItem() {
      if(this.selectedItem?.position) {
        let position = [this.selectedItem.position.latitude, this.selectedItem.position.longitude];
        if(!this.map.getBounds().contains(position)) {
          this.$refs.map.mapObject.panTo(position)
        }
      }
    },
  },

  computed: {

  },

  methods: {
    fitBounds() {
      if(this.items.length === 1) {
        this.flyToItem(this.items[0].position)
      } else {
        let bounds = []
        this.items.forEach(cp => {
          if(cp.position?.latitude) {
            bounds.push([cp.position.latitude, cp.position.longitude])
          }
        })
        if(bounds.length > 0) {
          this.map.flyToBounds(bounds, {padding: [20, 20], duration: 0.5})
        }
      }
    },

    _onMapReady() {
      this.map = this.$refs.map.mapObject
      this.map.attributionControl.setPrefix('')
      this.map.on('zoomend',  () => {
        this.hideCanvaMarker = false
      });
      this.fitBounds()
      this.onMapReady(this.map)
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
      if(this.selectedItem?.position) {
        this.isSelectItemVisible = this.isPointVisible([this.selectedItem.position.latitude, this.selectedItem.position.longitude])
      }
    },
    isPointVisible(position) {
      return this.map.getBounds().contains(position)
    },

    flyToItem(position, zoom = 18) {
      if(position?.latitude) {
        if(
            this.currentZoom !== zoom ||
            this.currentCenter.lat !== position.latitude  ||
            this.currentCenter.lng !== position.longitude
        ) {
          this.currentZoom = zoom
          this.hideCanvaMarker = true
          this.map.flyTo([position.latitude, position.longitude], zoom, {duration: 0.5})
        }
      }
    },
    centerMap() {
      this.$refs.map.mapObject.panTo(this.selectedItem?.position ? [this.selectedItem.position.latitude, this.selectedItem.position.longitude] : this.center)
    },

    colorForStation(item) {

      let color = 'black';
      if(item.status.filter(s => s !== "AVAILABLE").length === 0) {
        color = "#00897E";
      }
      else if(item.status.filter(s => s !== "OFFLINE" && s !== "OUTOFORDER").length === 0) {
        color = "#E53C22";
      }
      else if(item.status.filter(s => s === "OFFLINE" || s === "OUTOFORDER").length > 0) {
        color = "#fa7766";
      }
      else if(item.status.filter(s => s === "CHARGING").length > 0) {
        color = "#DEAC3C";
      }
      else if(item.status.filter(s => s === "BLOCKED").length > 0) {
        color = "#b3028d";
      }

      // console.log("colorForStation", item.id, color)

      return color

    },

    opacityForStation(item) {
      return 1
    },

    openChargingPoint(item) {
      let routeName = this.isLocation ? 'location.detail' : 'charging-points.detail';
      let url = this.$router.resolve({ name: routeName, params: { id: item.id } });
      window.open(url.href, '_blank');
    }
  }
}
</script>

<style lang="scss">
.map-block {
  flex: 1;
  position: relative;
  z-index: 0;
  .v-btn {
    background-color: #FFFFFF;
    border: 2px solid rgba(0,0,0,0.2);
    padding: 5px;
    width: 40px;
    height: 40px;
    .v-icon {
      color: #E53C22;
    }
  }

  .leaflet-marker-pane {
    canvas {
      z-index: 1 !important;
    }
  }
}


.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}

.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}


</style>
